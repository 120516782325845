import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Page, Header, Content, ContentHeader, SupportButton, InfoCard } from '@backstage/core-components';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import DomainIcon from '@material-ui/icons/Domain';
import BuildIcon from '@material-ui/icons/Build';
import DescriptionIcon from '@material-ui/icons/Description';
import SpeedIcon from '@material-ui/icons/Speed';
import ApiIcon from '@material-ui/icons/Speed';
import ArchitectureIcon from '@material-ui/icons/Speed';
import CodeIcon from '@material-ui/icons/Code';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    overviewCard: {
        marginBottom: theme.spacing(4),
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    componentCard: {
        height: '100%',
        width: '100%',
    },
    cardContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    gridContainer: {
        width: '100%',
    },
    icon: {
        fontSize: 40,
        marginBottom: theme.spacing(1),
    },
    systemsCard: {
        backgroundColor: '#2c387e',
    },
    domainsCard: {
        backgroundColor: '#1769aa',
    },
    adrCard: {
        backgroundColor: '#008394',
    },
    faastCard: {
        backgroundColor: '#00695f',
    },
    techRadarCard: {
        backgroundColor: '#357a38',
    },
    apiGuideCard: {
        backgroundColor: '#482880',
    },
    structurizrCard: {
        backgroundColor: '#b28704',
    },
    apiCard: {
        backgroundColor: '#aa2e25',
    },
    componentsCard: {
        backgroundColor: '#0276aa',
    },
}));

const HomePage = () => {
    const classes = useStyles();
    const configApi = useApi(configApiRef);
    const appTitle = configApi.getString('app.title');

    return (
        <Page themeId='home'>
            <Header title={appTitle} subtitle='Developer Portal for Porsche Products' />
            <Content>
                <ContentHeader title=''>
                    <SupportButton>Need help? Contact the Backstage team.</SupportButton>
                </ContentHeader>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <InfoCard title='About Backstage' className={classes.overviewCard}>
                            <Typography variant='body1'>
                                Backstage is an open-source platform for building developer portals. It's based on a centralized software
                                catalog and offers a rich set of tools for software development lifecycle.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoCard
                            title='Systems'
                            className={`${classes.componentCard} ${classes.systemsCard}`}
                            deepLink={{
                                title: 'Explore Systems',
                                link: '/catalog?filters[kind]=system',
                            }}
                        >
                            <AccountTreeIcon className={classes.icon} />
                            <Typography variant='body2'>
                                Systems represent the high-level components of your software ecosystem. They help in organizing and managing
                                large-scale architectures.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoCard
                            title='Domains'
                            className={`${classes.componentCard} ${classes.domainsCard}`}
                            deepLink={{
                                title: 'Explore Domains',
                                link: '/catalog?filters[kind]=domain',
                            }}
                        >
                            <DomainIcon className={classes.icon} />
                            <Typography variant='body2'>
                                Domains group together entities that share a common purpose or business function, helping to organize your
                                software catalog.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoCard
                            title='Components'
                            className={`${classes.componentCard} ${classes.componentsCard}`}
                            deepLink={{
                                title: 'Explore Components',
                                link: '/catalog?filters[kind]=component',
                            }}
                        >
                            <BuildIcon className={classes.icon} />
                            <Typography variant='body2'>
                                Components are the individual building blocks of your software architecture. They can represent services,
                                libraries, or other reusable parts of your systems.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoCard
                            title='ADR'
                            className={`${classes.componentCard} ${classes.adrCard}`}
                            deepLink={{
                                title: 'View ADRs',
                                link: '/adr-overview',
                            }}
                        >
                            <DescriptionIcon className={classes.icon} />
                            <Typography variant='body2'>
                                Architectural Decision Records (ADRs) document important architectural decisions made along with their
                                context and consequences.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoCard
                            title='FAAST'
                            className={`${classes.componentCard} ${classes.faastCard}`}
                            deepLink={{
                                title: 'FAAST Overview',
                                link: '/faast-overview',
                            }}
                        >
                            <SpeedIcon className={classes.icon} />
                            <Typography variant='body2'>
                                Framework of Agile Assurance Skills Template (FAAST) provides a structured approach to ensure agile
                                development practices and skills.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoCard
                            title='Tech Radar'
                            className={`${classes.componentCard} ${classes.techRadarCard}`}
                            deepLink={{
                                title: 'Tech Radar Overview',
                                link: '/tech-radar-overview',
                            }}
                        >
                            <SpeedIcon className={classes.icon} />
                            <Typography variant='body2'>
                                The Tech Radar provides an overview of various technologies, frameworks, tools, and platforms used or being
                                evaluated in your organization.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoCard
                            title='API Guide'
                            className={`${classes.componentCard} ${classes.apiGuideCard}`}
                            deepLink={{
                                title: 'API Guide',
                                link: '/api-guide',
                            }}
                        >
                            <ApiIcon className={classes.icon} />
                            <Typography variant='body2'>
                                The API Guide provides best practices, standards, and guidelines for designing, developing, and consuming
                                APIs within your organization.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoCard
                            title='Structurizr'
                            className={`${classes.componentCard} ${classes.structurizrCard}`}
                            deepLink={{
                                title: 'Structurizr',
                                link: '/structurizr',
                            }}
                        >
                            <ArchitectureIcon className={classes.icon} />
                            <Typography variant='body2'>
                                Structurizr is a tool for creating software architecture diagrams and documentation based on the C4 model,
                                helping to visualize and communicate system designs.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InfoCard
                            title='APIs'
                            className={`${classes.componentCard} ${classes.apiCard}`}
                            deepLink={{
                                title: 'Explore APIs',
                                link: '/catalog?filters[kind]=api',
                            }}
                        >
                            <CodeIcon className={classes.icon} />
                            <Typography variant='body2'>
                                Explore and manage the APIs in your organization. This includes API specifications, documentation, and
                                integration details for various services and systems.
                            </Typography>
                        </InfoCard>
                    </Grid>
                </Grid>
            </Content>
        </Page>
    );
};

export default HomePage;
