import React from 'react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import { Page, Header, Content, ContentHeader, SupportButton, InfoCard } from '@backstage/core-components';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    overviewCard: {
        marginBottom: theme.spacing(4),
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    componentCard: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    gridContainer: {
        width: '100%',
    },
    linkContainer: {
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: theme.spacing(2),
    },
    link: {
        color: theme.palette.common.white,
        '&:hover': {
            textDecoration: 'none',
            opacity: 0.8,
        },
    },
    faastCard: {
        backgroundColor: '#00695f',
    },
    carSalesCard: {
        backgroundColor: '#1769aa',
    },
    myPorscheCard: {
        backgroundColor: '#357a38',
    },
    digitalProductsCard: {
        backgroundColor: '#6d1b7b',
    },
}));

const resourcesData = [
    {
        title: 'FAAST',
        description: 'The latest version of the FAAST is available here.',
        links: [
            { title: 'Explore', url: 'https://faast.slsystem.pdcp.porsche.digital' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/faast' },
        ],
        className: 'faastCard',
    },
    {
        title: 'Car Sales',
        description: 'FAAST implementation for Car Sales.',
        links: [
            { title: 'Explore', url: 'https://redesigned-couscous-9klekvn.pages.github.io' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/faast-cs-art' },
        ],
        className: 'carSalesCard',
    },
    {
        title: 'My Porsche',
        description: 'FAAST implementation for My Porsche.',
        links: [
            { title: 'Explore', url: 'https://myp.faast.porsche.digital/' },
            { title: 'GitHub', url: 'https://github.com/myporsche/faast-myp-art' },
        ],
        className: 'myPorscheCard',
    },
    {
        title: 'Digital Products',
        description: 'FAAST implementation for Digital Products.',
        links: [
            { title: 'Explore', url: 'https://dp.faast.porsche.digital/' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/faast-dp-art' },
        ],
        className: 'digitalProductsCard',
    },
];

export const FaastOverview: React.FC = () => {
    const classes = useStyles();

    return (
        <Page themeId='home'>
            <Header title='FAAST Overview' subtitle='Framework of Agile Assurance Skills Template' />
            <Content>
                <ContentHeader title=''>
                    <SupportButton>Need help with FAAST? Contact the FAAST team.</SupportButton>
                </ContentHeader>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <InfoCard title='About FAAST' className={classes.overviewCard}>
                            <Typography variant='body1'>
                                FAAST (Framework of Agile Assurance Skills Template) provides a structured approach to ensure agile
                                development practices and skills. Here you can find a collection of all FAAST implementations in the
                                customer portfolio.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    {resourcesData.map((resource) => (
                        <Grid item xs={12} md={6} key={resource.title}>
                            <InfoCard
                                title={resource.title}
                                className={`${classes.componentCard} ${classes[resource.className as keyof typeof classes]}`}
                            >
                                <Typography variant='body2' style={{ marginBottom: '16px' }}>
                                    {resource.description}
                                </Typography>
                                <div className={classes.linkContainer}>
                                    {resource.links.map((link) => (
                                        <Button
                                            key={link.url}
                                            variant='outlined'
                                            className={classes.link}
                                            href={link.url}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {link.title}
                                        </Button>
                                    ))}
                                </div>
                            </InfoCard>
                        </Grid>
                    ))}
                </Grid>
            </Content>
        </Page>
    );
};
