import styled from 'styled-components';
import React from 'react';

interface TableOfContentComponentProps {
    markdownContent: string;
}

interface TableOfContentProps {
    id: number;
    title: string;
    anchor: string;
}

function getToc(markdown: string) {
    const regXHeader = /#{1,6}.+/g;
    const regexReplaceCode = /(```.+?```)/gms;
    const regexRemoveLinks = /\[(.*?)\]\(.*?\)/g;

    const markdownWithoutLinks = markdown.replace(regexRemoveLinks, '');
    const markdownWithoutCodeBlocks = markdownWithoutLinks.replace(regexReplaceCode, '');
    const titles = markdownWithoutCodeBlocks.match(regXHeader);

    const toc: TableOfContentProps[] = [];

    if (titles) {
        titles.map((tempTitle, i) => {
            const title = tempTitle.replace(/#/g, '').trim();
            const anchor = `#${title.replace(/[ ()]/g, '-').toLowerCase()}`;

            toc.push({
                id: i,
                title: title,
                anchor: anchor,
            });
        });
    }

    return toc;
}

export const TableOfContentComponent: React.FC<TableOfContentComponentProps> = ({ markdownContent }) => {
    const toc = getToc(markdownContent);
    return (
        <ToCWrapper>
            <ToCTitle>Table of contents</ToCTitle>
            <ToCList className='table-of-contents'>
                {toc.map(({ id, title, anchor }) => (
                    <ToCListItem key={id}>
                        <ToCItemTitle href={anchor}>{title}</ToCItemTitle>
                    </ToCListItem>
                ))}
            </ToCList>
        </ToCWrapper>
    );
};

const ToCWrapper = styled.div`
    position: sticky;
    top: var(--space-sm);
    margin-bottom: var(--space-sm);
    background-color: var(--content-bg);
    padding: var(--space-sm) var(--space-sm) 1px var(--space-sm);
    border-radius: var(--border-radius);
    font-size: 15px;
`;

const ToCTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: var(--space-sm);
`;

const ToCList = styled.ul`
    padding-inline-start: 0;
`;
const ToCListItem = styled.li`
    list-style-type: none;
    margin-bottom: 1rem;
    padding-left: calc(var(--space-sm) * 1.25);
    border-left: 1px solid var(--secondary-color);
    font-size: 14px;
`;

const ToCItemTitle = styled.a`
    transition: 0.2s;
    &:hover {
        text-decoration: underline;
        color: lightskyblue;
    }
`;
