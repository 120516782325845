import { Header, MarkdownContent, Page, Content, ContentHeader, SupportButton, InfoCard } from '@backstage/core-components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import apiSolutions from './docs/apiSolutions.md';
import howToTag from './docs/howToTag.md';
import populateTheCatalog from './docs/populateTheCatalog.md';
import provideApis from './docs/provideApis.md';
import writeTechnicalDoc from './docs/writeTechnicalDoc.md';
import apiStatusCodes from './docs/apiStatusCodes.md';
import asyncAPIs from './docs/asyncAPIs.md';
import { TableOfContentComponent } from './tableOfContent';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    overviewCard: {
        marginBottom: theme.spacing(4),
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    gridContainer: {
        width: '100%',
    },
}));

export const ApiGuide = () => {
    const [selectedContent, setSelectedContent] = useState(apiSolutions);
    const [markdownContent, setMarkdownContent] = useState(apiSolutions);
    const classes = useStyles();

    const location = useLocation(); // Get current location from react-router

    const handleContentChange = (newContent: string) => {
        setSelectedContent(newContent);
    };

    useEffect(() => {
        location.hash.substring(1);
    }, [location]);

    useEffect(() => {
        fetch(selectedContent)
            .then((response) => response.text())
            .then(setMarkdownContent)
            .catch((error) => console.error('Error fetching Markdown content:', error));
    }, [selectedContent]);

    return (
        <Page themeId='home'>
            <Header title='API Guide' subtitle='A Guide to get an Overview about all API Solutions and Providers' />
            <Content>
                <ContentHeader title=''>
                    <SupportButton>Need help with APIs? Contact the API team.</SupportButton>
                </ContentHeader>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <InfoCard title='About API Guide' className={classes.overviewCard}>
                            <Typography variant='body1'>
                                The API Guide provides comprehensive information about API solutions, design principles, and best practices.
                                Use this guide to learn about API development, documentation, and integration within our ecosystem.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    <Grid item xs={12}>
                        <Layout>
                            <LeftNav>
                                <NavTitle>Navigation</NavTitle>
                                <NavLink onClick={() => handleContentChange(apiSolutions)}>Which API Solution do we have</NavLink>
                                <NavLink onClick={() => handleContentChange(apiStatusCodes)}>How to Design a REST API</NavLink>
                                <NavLink onClick={() => handleContentChange(asyncAPIs)}>How to Design an async API</NavLink>
                                <NavLink onClick={() => handleContentChange(populateTheCatalog)}>How to populate the catalog</NavLink>
                                <NavLink onClick={() => handleContentChange(howToTag)}>How to Tag your Catalog</NavLink>
                                <NavLink onClick={() => handleContentChange(writeTechnicalDoc)}>
                                    How to write a technical documentation
                                </NavLink>
                                <NavLink onClick={() => handleContentChange(provideApis)}>How to provide my API in backstage</NavLink>
                                <TableOfContentContainer>
                                    <TableOfContentComponent markdownContent={markdownContent} />
                                </TableOfContentContainer>
                            </LeftNav>
                            <ContentContainer>
                                <MarkdownContent content={markdownContent} />
                            </ContentContainer>
                        </Layout>
                    </Grid>
                </Grid>
            </Content>
        </Page>
    );
};

const Layout = styled.div`
    display: flex;
    padding: 16px;
`;

const NavTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: var(--space-sm);
`;

const LeftNav = styled.nav`
    position: sticky;
    top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
    margin-left: 32px;
    margin-right: 32px;
    align-self: flex-start;
`;

const TableOfContentContainer = styled.div`
    overflow-y: auto;
    max-height: calc(100vh - 340px);
`;

const NavLink = styled.span`
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  calc(var(--md-typeset-font-size) * 0.9);
  &:hover {
    text-decoration: underline;
    color: lightskyblue;
  }
`;

const ContentContainer = styled.div`
    flex: 1;
    overflow-y: auto;
`;
