import React from 'react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import { Page, Header, Content, ContentHeader, SupportButton, InfoCard } from '@backstage/core-components';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    overviewCard: {
        marginBottom: theme.spacing(4),
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    componentCard: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    gridContainer: {
        width: '100%',
    },
    linkContainer: {
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: theme.spacing(2),
    },
    link: {
        color: theme.palette.common.white,
        '&:hover': {
            textDecoration: 'none',
            opacity: 0.8,
        },
    },
    csAdrCard: {
        backgroundColor: '#1769aa',
    },
    customerAdrCard: {
        backgroundColor: '#357a38',
    },
}));

const resourcesData = [
    {
        title: 'Car Sales ADR',
        description: 'Architectural Decision Records for the Car Sales domain.',
        links: [
            { title: 'View ADR', url: '/docs/default/Domain/cs-adr' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/cs-adr' },
        ],
        className: 'csAdrCard',
    },
    {
        title: 'Customer ADR',
        description: 'Architectural Decision Records for the Customer domain.',
        links: [
            { title: 'View ADR', url: '/docs/default/Domain/customer-adr' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/customer-adr' },
        ],
        className: 'customerAdrCard',
    },
];

export const ADROverview: React.FC = () => {
    const classes = useStyles();

    return (
        <Page themeId='home'>
            <Header title='ADR' subtitle='Architectural Decision Records' />
            <Content>
                <ContentHeader title=''>
                    <SupportButton>Need help with ADRs? Contact the Architecture team.</SupportButton>
                </ContentHeader>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <InfoCard title='About ADRs' className={classes.overviewCard}>
                            <Typography variant='body1'>
                                Architectural Decision Records (ADRs) document important architectural decisions made along with their
                                context and consequences. Here you can find ADRs for different domains within our organization.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    {resourcesData.map((resource) => (
                        <Grid item xs={12} md={6} key={resource.title}>
                            <InfoCard
                                title={resource.title}
                                className={`${classes.componentCard} ${classes[resource.className as keyof typeof classes]}`}
                            >
                                <Typography variant='body2' style={{ marginBottom: '16px' }}>
                                    {resource.description}
                                </Typography>
                                <div className={classes.linkContainer}>
                                    {resource.links.map((link) => (
                                        <Button
                                            key={link.url}
                                            variant='outlined'
                                            className={classes.link}
                                            href={link.url}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {link.title}
                                        </Button>
                                    ))}
                                </div>
                            </InfoCard>
                        </Grid>
                    ))}
                </Grid>
            </Content>
        </Page>
    );
};
