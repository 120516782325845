import { ScmIntegrationsApi, scmIntegrationsApiRef, ScmAuth } from '@backstage/integration-react';
import { AnyApiFactory, configApiRef, createApiFactory } from '@backstage/core-plugin-api';
import { OcsTechRadarApi } from './lib/OcsTechRadarApi';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';

const factory = createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
});

export const apis: AnyApiFactory[] = [factory, ScmAuth.createDefaultApiFactory(), createApiFactory(techRadarApiRef, new OcsTechRadarApi())];
