import { RadarRing, RadarQuadrant, RadarEntry, TechRadarLoaderResponse, TechRadarApi } from '@backstage-community/plugin-tech-radar';
import RadarEntries from './radar.json';

const rings = new Array<RadarRing>();
rings.push({ id: 'adopt', name: 'ADOPT', color: '#86B782' });
rings.push({ id: 'trial', name: 'TRIAL', color: '#1EBCCD' });
rings.push({ id: 'assess', name: 'ASSESS', color: '#F38A3E' });
rings.push({ id: 'hold', name: 'HOLD', color: '#B32059' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'tools', name: 'Tools' });
quadrants.push({ id: 'techniques', name: 'Techniques' });
quadrants.push({ id: 'services', name: 'Services' });
quadrants.push({ id: 'langfram', name: 'Languages & Frameworks' });

const entries = new Array<RadarEntry>();
RadarEntries.RadarEntries.forEach((element) => {
    entries.push({
        timeline: [
            {
                moved: element.timeline[0].moved,
                ringId: element.timeline[0].ringId,
                date: new Date(element.timeline[0].date),
                description: element.timeline[0].description,
            },
        ],
        url: element.url,
        key: element.key,
        id: element.id,
        title: element.title,
        quadrant: element.quadrant,
        description: element.description,
    });
});

export const mock: TechRadarLoaderResponse = {
    entries,
    quadrants,
    rings,
};

export class OcsTechRadarApi implements TechRadarApi {
    async load() {
        // eslint-disable-next-line no-console
        console.debug(mock);
        return mock;
    }
}
